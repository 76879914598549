body {
  margin: 0;
  padding: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans",
    "Droid Sans", "Helvetica Neue", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: #f7f7f7;
}

.App {
  position: relative;
  display: grid;
  grid-template-columns: 100%;
  justify-items: center;
  align-items: center;
  height: 720px;
}

:not(#call-wrapper) .heading {
  text-align: center;
  align-self: flex-start;
  margin-bottom: 0;
}

#call-wrapper #assistant {
  min-height: 100px;
  position: relative;
  margin-top: 25px;
  background: #ffff;
  border-radius: 6px;
  box-shadow: 0px 10px 20px 0px rgb(55 64 157 / 15%);
}

#call-wrapper .heading {
  display: none;
}

#videos {
  position: relative;
  height: 85vh;
  width: 100vw;
  margin: auto;
  align-self: flex-start;
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(440px, 1fr));
  justify-items: center;
  align-items: center;
}

.vid {
  position: relative;
  background-color: black;
  border-width: 1px;
  border-color: #38373a;
  border-style: solid;
}

.controls {
  position: absolute;
  bottom: 20px;
  left: 50%;
  transform: translateX(-50%);  
  display: grid;
  grid-template-columns: repeat(3, 33%);
  align-items: center;
  justify-items: center;
  z-index: 1;
  width: 500px;
  max-width: 60vw;
}

.local-video {
  height: 144px;
  width: 190px;
  position: absolute;
  top: 10px;
  right: calc(50% - 340px);
  z-index: 10;
}

.remote-video {
  height: 100%;
  width: 100%;    
}

.player-canvas {
  position: absolute;
  z-index: 5;
}

#remote-video-container {
  position: absolute;
  width: 720px;
  height: 720px;
  left: 50%;
  transform: translateX(-50%);
  top: 0px;  
}

.controls p {
  padding: 10px;
  cursor: pointer;
  background: #38373a;
  color: #f7f7f7;
  border-width: 1px;
  border-color: #f7f7f7;
  border-style: solid;
}

.controls p.on {
  background: #f7f7f7;
  color: #38373a;
  border-width: 1px;
  border-color: #38373a;
  border-style: solid;
}

:not(#call-wrapper) .join {
  position: absolute;
  z-index: 1;
  width: 30vw;
  height: fit-content;
  height: -moz-max-content;
  top: 50vh;
  left: 50vw;
  transform: translate(-50%, -50%);
  width: 500px;
  max-width: 75vw;
}

#call-wrapper .join {
  height: -webkit-fit-content;
  height: -moz-fit-content;
  height: fit-content;
  height: -moz-max-content;
  left: 50%;
  top: auto;
  bottom: 5px;
  z-index: 1;
}

.join input {
  padding: 15px;
  font-size: 1rem;
  border-width: 1px;
  border-color: #38373a;
  border-style: solid;
  width: 80%;
  display: block;
  margin: 50px auto;
}

:not(#call-wrapper) .join button {
  min-width: 200px;
  padding: 12px 0;
  text-align: center;
  background-color: #3c7cdb;
  color: #f7f7f7;
  border-width: 1px;
  border-color: #f7f7f7;
  border-style: solid;
  font-size: 1rem;
  font-weight: 400;
  cursor: pointer;
  display: block;
  margin: 0 auto;
  border-radius: 12px;
}

#call-wrapper .join button {
  background-color: #20c497;
  border-radius: 6px;
  font-size: 14px;
  font-weight: 600;
  padding: 12px 25px;
}
